<app-header [imageLinkable]="false"></app-header>

<div class="bolt-container pb-10">
    <!-- moved from admin-page -->
    <div class="bolt-row pt-5" *ngIf="isSuccess">
        <div class="bolt-col">
            <bolt-notification type="success">The device has been updated successfully.</bolt-notification>
        </div>
    </div>

    <div class="bolt-row pt-5 pb-5">
        <div class="bolt-col">
            <span class="font-size-xl heading">Smart home device management tool</span>
        </div>
    </div>
    <div class="bolt-row pb-5">
        <div class="bolt-col">
            <span>You can view and edit the number of free devices remaining and cost per device for different
                partners.</span>
        </div>
    </div>
    <!-- moved from admin-page -->
    <div class="container-bar">
        <p class="d-inline date-text">Last updated: {{ lastUpdateTs | date: 'MM/dd/yyyy' }}</p>
        <bolt-button size="sm" iconright="download" (click)="exportToExcel()" class="p-0 d-inline download-button">
            Download table
        </bolt-button>
    </div>
    <bolt-waiting-indicator *ngIf="isLoading">Loading...</bolt-waiting-indicator>
    <div class="bolt-row m-auto" *ngIf="!isLoading">
        <div class="bolt-col p-0">
            <div class="bolt-table bolt-table-vibrant-blue">
                <table #table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource"
                    (matSortChange)="sortData($event)" matSort>
                    <ng-container matColumnDef="State">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">State</th>
                        <td mat-cell *matCellDef="let element">{{element.State}}</td>
                    </ng-container>
                    <ng-container matColumnDef="Policy">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">Policy</th>
                        <td mat-cell *matCellDef="let element">{{element.Policy}}</td>
                    </ng-container>
                    <ng-container matColumnDef="Coverage_A_Below">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">Coverage A Below
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.Coverage_A_Below}}</td>
                    </ng-container>
                    <ng-container matColumnDef="Device_Type_1">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">Device Type 1
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.Device_Type_1}}</td>
                    </ng-container>
                    <ng-container matColumnDef="Device_Vendor_1">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">Device Vendor 1
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.Device_Vendor_1}}</td>
                    </ng-container>
                    <ng-container matColumnDef="Device_Offer_1">
                        <th mat-header-cell mat-sort-header mat-sort-header *matHeaderCellDef class="table-header-cell">
                            Device Offer 1</th>
                        <td mat-cell *matCellDef="let element">{{element.Device_Offer_1}}</td>
                    </ng-container>
                    <ng-container matColumnDef="Device_Savings_1">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">Device Savings 1
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.Device_Savings_1}}</td>
                    </ng-container>
                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <!-- <mat-icon>more_vert</mat-icon> -->
                            <button mat-icon-button [matMenuTriggerFor]="appMenu" class="menu-icon">
                                <mat-icon class="icon-view">more_horiz</mat-icon>
                            </button>
                            <mat-menu #appMenu="matMenu" [overlapTrigger]="false" class="option-container">
                                <button mat-menu-item class="options-button">Edit</button>
                                <button mat-menu-item class="options-button">Duplicate</button>
                                <button mat-menu-item class="options-button">Delete</button>
                            </mat-menu>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
</div>