import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecommendationsLandingComponent } from './pages/recommendations/recommendations-landing/recommendations-landing.component';
import { AuthCallbackComponent } from './pages/quick-authentication/auth-callback/auth-callback.component';
import { IsAdmin, IsAuthenticated, IsProviderSearchPermitted } from './core/services/auth-guard/auth-guard.service';
import { AutoLoginAllRoutesGuard } from 'angular-auth-oidc-client';
import { ErrorComponent } from './pages/problem-pages/error-page.component';
import { DeviceRecommendationTableComponent } from './pages/admin-page/device-recommendation-table/device-recommendation-table.component';

const routes: Routes = [
  { path: 'recommendations', component: RecommendationsLandingComponent },
  { path: 'landing', loadChildren: () => import('./pages/device-landing/device-landing.module').then((m) => m.DeviceLandingModule), data: { breadcrumb: 'landing' }, canActivate: [IsAuthenticated] },
  { path: 'quick-auth', loadChildren: () => import('./pages/quick-authentication/quick-authentication.module').then((m) => m.QuickAuthenticationModule), data: { breadcrumb: 'auth' }, },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'admin', loadChildren: () => import('./pages/admin-page/admin-page.module').then((m) => m.AdminPageModule), data: { breadcrumb: 'admin' }, canActivate: [AutoLoginAllRoutesGuard, IsAdmin] },
  { path: 'device-recommendation-table', component: DeviceRecommendationTableComponent },
  { path: 'provider-search', loadChildren: () => import('./pages/provider-search/provider-search.module').then((m) => m.ProviderSearchModule), data: { breadcrumb: 'provider-search' }, canActivate: [AutoLoginAllRoutesGuard, IsProviderSearchPermitted] },
  { path: 'error', loadChildren: () => import('./pages/problem-pages/error-page.module').then((m) => m.ErrorModule), data: { breadcrumb: 'error' }, },
  { path: 'campaigns', loadChildren: () => import('./pages/campaigns/campaigns.module').then(m => m.CampaignsModule), data: { breadcrumb: 'campaigns' }, },
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: '**', component: ErrorComponent, data: { breadcrumb: 'error', pageExists: false } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }