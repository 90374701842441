import { Component, ElementRef, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-device-recommendation-table',
  templateUrl: './device-recommendation-table.component.html',
  styleUrls: ['./device-recommendation-table.component.less']
})
export class DeviceRecommendationTableComponent {
  @ViewChild(MatPaginator, ({static: true})) paginator!: MatPaginator;
  totalRecords:number = 0;
  pageSize:number = 2;
  lastUpdateTs: Date | undefined;
  isLoading = false;
  isSuccess: boolean = false;
  dataSource = new MatTableDataSource;
  data: any[] = [];
  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild('table', { read: ElementRef })
  table!: ElementRef;
  pageIndex = 0;
  goToPage: any;
  currentPage: number = 0;
  paginatedItems: any[] = [];
  totalPages: number = 0;
  pageNumbers: number[] = [];
  displayColumns: string[] = ['State', 'Policy','Coverage_A_Below', 'Device_Type_1','Device_Offer_1','Device_Vendor_1','Device_Savings_1','Actions'];

  constructor() {
    this.data = [{State:'Default',Policy: 'Condo','Coverage_A_Below': 'null', 'Device_Type_1': 'Water Spot Sensors', 'Device_Vendor_1': 'Leakbot', 'Device_Offer_1': 'Comp', 'Device_Savings_1': '100%'
    },
    {State:'Ohio',Policy: 'MHO','Coverage_A_Below': '500,000', 'Device_Type_1': 'Water Flow Sensors', 'Device_Vendor_1': 'Notion', 'Device_Offer_1': 'Purchase', 'Device_Savings_1': '50%'
    },
    {State:'Ohio',Policy: 'MHO','Coverage_A_Below': '500,000', 'Device_Type_1': 'Water Flow Sensors', 'Device_Vendor_1': 'Notion', 'Device_Offer_1': 'Purchase', 'Device_Savings_1': '50%'
    },
    {State:'Ohio',Policy: 'MHO','Coverage_A_Below': '500,000', 'Device_Type_1': 'Water Flow Sensors', 'Device_Vendor_1': 'Notion', 'Device_Offer_1': 'Purchase', 'Device_Savings_1': '50%'
    },
    {State:'Ohio',Policy: 'MHO','Coverage_A_Below': '500,000', 'Device_Type_1': 'Water Flow Sensors', 'Device_Vendor_1': 'Notion', 'Device_Offer_1': 'Purchase', 'Device_Savings_1': '50%'
    },
    {State:'Ohio',Policy: 'MHO','Coverage_A_Below': '500,000', 'Device_Type_1': 'Water Flow Sensors', 'Device_Vendor_1': 'Notion', 'Device_Offer_1': 'Purchase', 'Device_Savings_1': '50%'
    }];
    this.dataSource.data = this.data;
  }

  ngOnInit(): void {
    this.totalRecords = this.dataSource.data.length;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  exportToExcel() {
    const sheet: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.data);
    const book: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book,sheet,'Device');
    XLSX.writeFile(book,'DeviceData.xlsx')
  }

  sortData($event: any) {
    const sortId = $event.active;
    const sortDirection = $event.direction;
    if ('asc' == sortDirection) {
      this.dataSource.data = this.data.slice().sort(
        (a,b) => a[sortId] > b[sortId] ? -1 : this.sortASC(a,b,sortId)
      );
    } else {
      this.dataSource.data = this.data.slice().sort(
        (a,b) => a[sortId]  < b[sortId] ? -1 : this.sortDESC(a,b,sortId)
      );
    }
  }

  sortASC(a: any, b: any, sortId: any) {
    return a[sortId] <b[sortId] ? 1:0 ;
  }

  sortDESC(a: any, b: any, sortId: any) {
    return a[sortId] > b[sortId] ? 1:0 ;
  }
}